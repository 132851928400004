@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    scroll-behavior: smooth;
}

body {
    font-family: 'Anta', serif;
    color: white;
    /* background: black; */
}

/* html {
    padding-top: 5%;
} */

p {
    padding: 10px; 
}

h1 {
    font-size: 7vh;
    font-weight: 500;
    -webkit-text-stroke: 1.4px;
    -webkit-text-stroke-color: rgb(66, 66, 66);
}

h2 { 
    font-size: x-large;
}

.logo-bg {
    background-image: url("./assets/images/JSLogo1.jpg");
    background-repeat: no-repeat;
    background-size: 100vw;
    min-height: 100px;
    /* background-color: black; */
    /* background: rgba(76, 175, 80, 0.6); */
}

.no-bg {
    background-image: none;
}

@media screen and (max-width: 768px) {
    .recital-bg{
        background-image: none;
        min-height: 200px;
    }

    h1 {
        font-size: 5vh;
    }
    
}